<div class="container">
<form class="float-label" spellcheck="false">
  <div *ngIf="error | async as errormessage">
    <div class="col-xs-12 error-container" *ngIf="errormessage !== ''">
      <small class="error-message">Er is iets fout gegaan! Kijk je inloggegevens na.</small>
    </div>
  </div>
  <div class="control">
    <input id="email" type="text" name="email" [(ngModel)]="userCredentials.email" placeholder="Email" required />
    <label for="email">Email</label>
  </div>

  <div class="control control--password">
    <input id="wachtwoord" type="password" name="wachtwoord" [(ngModel)]="userCredentials.password" placeholder="Wachtwoord" required />
    <label for="wachtwoord">Wachtwoord</label>
  </div>
  <div>
  <small class="forgot-password-button" [routerLink]="['/forgot-password']">Wachtwoord vergeten?</small>
  </div>

  <button class="btn btn-primary login-button" (click)="login()">Inloggen</button>
  <div style="text-align:center">
  <small class="register-label" [routerLink]="['/register']">Nog geen account?</small>
  </div>
</form>
</div>
