<div class="container">
<form class="float-label" spellcheck="false">
  <div *ngIf="error | async as errormessage">
    <div class="col-xs-12 error-container" *ngIf="errormessage !== ''">
      <small class="error-message">Dit emailadres zit niet in de database</small>
    </div>
  </div>
  <div class="control">
    <input id="email" type="text" name="email" [(ngModel)]="email" placeholder="Email" required />
    <label for="email">Email</label>
  </div>

  <button class="btn btn-primary login-button" (click)="sendEmail()">Verstuur e-mail</button>
  <div style="text-align:center">
  <small class="register-label" [routerLink]="['/login']">Weet je jouw wachtwoord?</small>
  </div>
</form>
</div>
