<div class="container">
  <form class="float-label" spellcheck="false">
      <div class="col-xs-12 error-container" *ngIf="emailError">
        <small class="error-message">Je bent geen lid van Team Awesome. Gebruik je iDA e-mailadres.</small>
      </div>

      <div class="col-xs-12 error-container" *ngIf="passwordError">
        <small class="error-message">Je wachtwoord is slecht geformateerd.</small>
      </div>

    <div class="control">
      <input id="naam" type="text" name="naam" [(ngModel)]="userCredentials.name" placeholder="Naam en achternaam" required />
      <label for="naam">Naam en achternaam</label>
    </div>

    <div class="control">
      <input id="email" type="text" name="email" [(ngModel)]="userCredentials.email" placeholder="Email" required />
      <label for="email">Email</label>
    </div>

    <div class="control">
      <input id="wachtwoord" type="password" name="wachtwoord" [(ngModel)]="userCredentials.password" placeholder="Wachtwoord" required />
      <label for="wachtwoord">Wachtwoord</label>
    </div>

    <div class="control">
      <input id="repeatedPassword" type="password" name="repeatedPassword" [(ngModel)]="repeatedPassword" placeholder="Herhaal wachtwoord" required />
      <label for="repeatedPassword">Herhaal wachtwoord</label>
    </div>

    <button class="btn btn-primary register-button" (click)="register()">Registreren</button>
    <div style="text-align:center">
      <small class="login-label" [routerLink]="['/login']">Reeds een account?</small>
    </div>
  </form>
</div>
