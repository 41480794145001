<div class="centered col-xs-12">
  <div class="col-xs-12 col-sm-6 buttonContainer">
    <div class="col-xs-offset-3 col-xs-6 buttonWrapper" [routerLink]="['/booklist']">
      <img src="../../../assets/icons/booklist.png" alt="booklist">
      <h3>Booklist</h3>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6 buttonContainer">
    <!--<div class="col-xs-offset-3 col-xs-6 buttonWrapper" [routerLink]="['/book-loan-qr']">-->
      <!--<img src="../../../assets/icons/barcode.png" alt="barcode">-->
    <div class="col-xs-offset-3 col-xs-6 buttonWrapper">
      <img src="../../../assets/images/under_construction.png" alt="under construction">
      <h3>Loan book with QR</h3>
    </div>
  </div>
</div>
