<button class="btn button-back col-xs-offset-1 col-xs-10 col-lg-offset-3 col-lg-6"
        [routerLink]="['/booklist']"><< Back
</button>
<div class="col-xs-offset-1 col-xs-10 col-lg-offset-3 col-lg-6 bookCard" *ngIf="book | async as book">
  <div class="col-xs-12 col-sm-5 bookImageDiv">
    <img [src]="book.imageUrl" alt="bookimage">
  </div>
  <h4 *ngIf="book.loanedOut == true" class="col-xs-8 col-sm-7 borrowed-span-wrapper"><span class="borrowed-span">Borrowed</span>
    {{book.loanedBy}}</h4>
  <h4 *ngIf="book.loanedOut == false" class="col-xs-6 col-sm-7 available-span-wrapper"><span class="available-span">Available</span>
  </h4>
  <h1 class="col-xs-12 col-sm-7 title">{{book.title}}</h1>
  <h2 class="col-xs-12 col-sm-7 book-author">{{book.author}}</h2>
  <h4 class="col-xs-12 col-sm-7 description">{{book.description}}</h4>
  <div class="col-xs-12 col-sm-7 buttonDiv">
    <button class="btn button-loan" *ngIf="book.loanedOut == false" (click)="setLoaned(book)">Borrow book</button>
    <button class="btn button-loan" *ngIf="book.loanedOut == true" (click)="bringBack(book)">Return book</button>
    <button class="btn button-loan button-userlist" [disabled]="book.alreadyRead.length == 0" data-toggle="modal" data-target="#userModal"><img src="../../../../assets/images/human_icon.png" alt="human icon"></button>
  </div>
</div>

<!-- MODAL -->
<div class="modal fade" id="userModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" *ngIf="book | async as book">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title col-xs-11" id="exampleModalLabel">People who already read this book</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul *ngIf="book.alreadyRead.length != 0">
          <li *ngFor="let name of book.alreadyRead">{{name}}</li>
        </ul>
        <p *ngIf="book.alreadyRead.length == 0">Nobody has read this book yet</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
